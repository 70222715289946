<template>
  <div>
    <div class="context">
      <div class="radioBox">
        <div class="label">匹配类型：</div>
        <div class="radio">
          <el-radio-group v-model="query.pushType" @change="search">
            <el-radio size="medium " :label="null" >全部</el-radio>
            <el-radio size="medium " :label="item.id" v-for="item in matchingType" :key="item.id">{{
              item.value
            }}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="button_time">
        <div class="button">
          <div class="publish" @click="issueSelect">发布已选</div>
          <div class="remove" @click="clickRemoveAll">
            <i class="el-icon-close"></i>
            移除已选
          </div>
        </div>
        <div class="time">
          <div class="icon">
            <img src="../../../../static/images/jr-icon-volume-copy.png" alt="" />
          </div>
          <div class="text">最新上架时间：</div>
          <div class="times">{{ newTime | timeFiltration }}</div>
        </div>
      </div>
      <div class="table">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          v-loading="loading"
          @sort-change="changeSort"
          element-loading-spinner="el-icon-loading"
          element-loading-text="加载中..."
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column type="selection" width="55" align="center"> </el-table-column>
          <el-table-column label="编号" align="center" type="index" width="80"> </el-table-column>

          <el-table-column label="logo" align="center">
            <template v-slot="row">
              <el-image
            v-if="row.companyLogo"
                slot="reference"
                :src="row.companyLogo"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>
              <el-image
                v-else
                slot="reference"
                src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/Group%203237.png"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column label="企业名称" align="center">
            <template slot-scope="scope">{{ scope.row.companyFullName }}</template>
          </el-table-column>
          <!-- <el-table-column label="所属行业" align="center">
            <template slot-scope="scope">{{ scope.row.industry }}</template>
          </el-table-column> -->
          <el-table-column label="所在城市" align="center">
            <template slot-scope="scope">{{ scope.row.province }}-{{ scope.row.city }}</template>
          </el-table-column>
        
          <el-table-column align="center" label="标签" width="300px">
              <template v-slot="{ row }">
                <span v-for="(o, index) in row.labelList" :key="index">
                  <span v-if="index < 4 && o != ''" class="span_tag">#{{ o }}</span>
                </span>
                <el-popover placement="bottom" width="230" trigger="hover">
                  <div
                    class="hidden_label_box"
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      align-content: center;
                      flex-wrap: wrap;
                    "
                  >
                    <span
                      class="span_tag"
                      v-for="(o, index) in row.labelList && row.labelList.length
                        ? row.labelList.filter((el, index) => index > 3)
                        : []"
                      :key="index"
                      style="
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        color: #4e93fb;
                        margin-right: 10px;
                      "
                      >#{{ o }}</span
                    >
                  </div>
                  <span
                    style="color: #4e93fb"
                    slot="reference"
                    v-if="row.labelList && row.labelList.length > 4"
                    >...{{ row.labelList.length - 4 }}</span
                  >
                </el-popover>
              </template>
            </el-table-column>
          <el-table-column label="关联度" prop="correlation" align="center" sortable>
            <template slot-scope="scope">
              <div class="correlationBox">
                <div class="correlation">{{ scope.row.correlation }}%</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="企业简介" align="center" width="350">
            <template slot-scope="scope">
              <div
                class="enterpriseIntro"
                @mouseenter="enterpriseIntroShow = scope.row.id"
                @mouseleave="enterpriseIntroShow = -1"
              >
                {{ scope.row.enterpriseIntro }}
              </div>
              <div
                class="enterpriseIntroShow"
                v-show="enterpriseIntroShow == scope.row.id"
                @mouseenter="enterpriseIntroShow = scope.row.id"
                @mouseleave="enterpriseIntroShow = -1"
              >
                {{ scope.row.enterpriseIntro }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div class="tableRemove">
                <div @click="clickRemove(scope.row)" class="tableRemoves">
                  <i class="el-icon-close"></i>
                  <div class="txt">移除</div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="Pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="query.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="query.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {
  matchingCompanyLists,
  deleteCorrelation,
  insertInformationCompany,
  informationDeleteCorrelation
} from '../../../api/content'
export default {
  props: {
    activeId: {
      type: String
    }
  },
  components: {},
  filters: {
    timeFiltration(time) {
      if (time) {
        return time.split(' ')[0]
      }
    }
  },
  data() {
    //这里存放数据
    return {
      matchingType: [
        {
          value: '算法匹配',
          id: 2
        },
        {
          value: '人工匹配',
          id: 1
        }
      ],
      //  匹配类型：选中的值
      radio: '',
      // 表格数据
      tableData: [],
      loading:false,
      // 表格企业简介
      enterpriseIntroShow: -1,
      // 表格选中的数据项
      multipleSelection: [],
      // 表格总数据条数
      total: 0,
      // 发送的数据
      query: {
        correlation: 0,
        correlationLike: 0,
        id: 0,
        pushType:null,
        orderByParam:"",
        pageNum: 1,
        pageSize: 10
      },
      //   最新上架时间
      newTime: ''
    }
  },

  watch: {},

  methods: {
  
    // table表格按钮的选中数据
    handleSelectionChange(val) {
      if (val) {
        this.multipleSelection = val
      }

      // console.log(this.multipleSelection)
    },
    // table的移除
    async clickRemove(row) {
      const idList = []
      idList.push(row.id)
      // console.log(this.activeId)
      const query = { id: this.activeId, idList }
      const { data: res } = await informationDeleteCorrelation(query)
      if (res.resultCode == 200) {
        this.search()
        this.$emit('getHeaderNum')
        return this.$message.success('移除成功')
      }
      // console.log(res)
    },
    // 批量移除
    async clickRemoveAll() {
      if (this.multipleSelection.length == 0) {
        return this.$message('请勾选要移除的数据')
      }
      const idList = []
      this.multipleSelection.forEach((item) => {
        idList.push(item.id)
      })
      const query = { id: this.activeId, idList }
      const { data: res } = await informationDeleteCorrelation(query)
      if (res.resultCode == 200) {
        this.search()
        this.$emit('getHeaderNum')
        return this.$message.success('移除成功')
      }
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.query.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    async search() {
      this.query.id =this.activeId
      this.loading = true
      const { data: res } = await matchingCompanyLists(this.query)
      // console.log(res)
      if (res.resultCode == 200) {
        this.total = res.data.total
        this.tableData = res.data.list
        this.loading =false
      }
    },
        //排序
        changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'correlation') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.query.orderByParam = 'm.correlation asc'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.query.orderByParam = 'm.correlation desc'
          this.search()
        } else {
          //updateAt列不排序时
          this.query.orderByParam = 'm.correlation desc'
          this.search()
        }
      }
    },
    // 发布已选
    async issueSelect() {
      if (this.multipleSelection.length == 0) {
        return this.$message('请勾选要发布的数据')
      }
      // console.log(this.multipleSelection)
      const arithmeticInformationParams = []
      this.multipleSelection.forEach((item) => {
        arithmeticInformationParams.push({
          arithmeticId:item.arithmeticId,
          companyId:item.id,
          correlation:item.correlation
        })
      })

      const query = {
        arithmeticInformationParams,
        id: this.$route.query.id
      }
      const { data: res } = await insertInformationCompany(query)

      if (res.resultCode == 200) {
        // console.log(res)
        this.$message.success('发布成功')
        this.$emit('getHeaderNum')
        this.search()
      }

      // console.log(this.multipleSelection)
    }
  },

  created() {
    // console.log(this.activeId)
    this.search()
    this.newTime = sessionStorage.getItem('time') || ''
  },

  mounted() {}
}
</script>
<style scoped lang="less">
.context {
  background-color: #fff;
  margin-top: 16px;
  border-radius: 8px;
  padding: 0 21px 0 24px;
  .span_tag {
  font-size: 14px;
  line-height: 20px;
  color: #4e93fb;
  margin-right: 10px;
  cursor: pointer;
}
  .radioBox {
    display: flex;
    padding: 20px 0;
    .label {
      font-size: 14px;
      color: #7c7f8e;
      margin-right: 12px;
    }
    .radio {
      /deep/.el-radio__label {
        color: #333;
        font-size: 14px;
      }
      /deep/.el-radio__input {
        width: 18px;
        height: 18px;
      }
      /deep/.el-radio__inner {
        width: 18px;
        height: 18px;
      }
      /deep/.el-radio {
        display: flex;
        align-items: center;
      }
      /deep/ .el-radio-group {
        display: flex;
      }
    }
  }

  .button_time {
    display: flex;
    justify-content: space-between;

    .button {
      display: flex;
      margin-bottom: 16px;
      .publish {
        width: 85px;
        height: 40px;
        border-radius: 4px;
        background-color: #4e93fb;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        margin-right: 15px;
        cursor: pointer;
      }
      .remove {
        box-sizing: border-box;
        width: 85px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #ff7d18;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff7d18;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .time {
      display: flex;
      padding-right: 60px;
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .table {
    .imgBox {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;

      .img {
        width: 34px;
        height: 34px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .correlationBox {
      display: flex;
      align-items: center;
      justify-content: center;
      .correlation {
        background-color: #fd6161;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 24px;
        color: #fff;
        font-size: 12px;
      }
    }

    .enterpriseIntro {
      // width: 100%;
      // height: 100%;
      width: 376px;
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
      // 控制行数
      -webkit-line-clamp: 2; //超出两行隐藏
      -webkit-box-orient: vertical; // 从上到下垂直排列子元素
      color: #333333;
      position: relative;
    }
    .enterpriseIntroShow {
      position: absolute;
      // right: 0%;
      // bottom: -23%;
      border-radius: 8px;
      // transform: translateX(50%);
      width: 376px;
      padding: 10px 7px 8px 13px;
      background-color: #fff;
      z-index: 999;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }

    /deep/.el-table__body-wrapper {
      overflow: visible;
    }
    /deep/.el-table {
      overflow: visible;
    }

    .tableRemove {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // color: #ff7d18;
      .tableRemoves {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff7d18;
        .txt {
          font-size: 14px;
          cursor: pointer;
        }
        /deep/.el-icon-close {
          margin-top: 3px;
          cursor: pointer;
        }
      }
    }
  }
  .Pagination {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 20px 0px;
  }
}
</style>
