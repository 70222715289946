<template>
  <div>
    <div class="head">
      <div v-for="item in headTab" :key="item.id" class="item" @click="clickTab(item)">
        <div class="text" :class="activeClass == item.id ? 'active_class' : ''">
          <div class="icon">
            <!-- <div> -->
            <img v-show="activeClass == 1" :src="item.url" alt="" />
            <img v-show="activeClass == 2" :src="item.url1" alt="" />
            <!-- <img :src="require('../../../../static/images/jr-icon-circle-copy.png')" alt="" /> -->

            <!-- </div> -->
            <!-- <i class="el-icon-edit"></i> -->
          </div>
          <div class="txt">{{ item.value }}</div>
          <div class="figure">({{ item.Num }})</div>
        </div>
        <div class="bottom" v-if="activeClass == item.id">
          <div class="bottom_a"></div>
        </div>
      </div>
    </div>
    <div class="context">
      <matchingCom @getHeaderNum="getHeaderNum"  v-if="activeClass == 1" :activeId="activeId" :type="type"></matchingCom>
      <notarizeSelect @getHeaderNum="getHeaderNum"   v-if="activeClass == 2" :activeId="activeId"></notarizeSelect>
    </div>
  </div>
</template>
<script>
import matchingCom from '../components/matchingCom'
import notarizeSelect from '../components/notarizeSelect'
import url1 from '../../../../static/images/jr-icon-six.png'
import url2 from '../../../../static/images/jr-icon-six-copy.png'
import url3 from '../../../../static/images/jr-icon-circle-copy.png'
import url4 from '../../../../static/images/jr-icon-circle.png'
import { matchingCompanyList,matchingCompanyLists ,artificialMatchingCompanyList} from '../../../api/content'
export default {
  components: { matchingCom, notarizeSelect },
 
  data() {
    //这里存放数据
    return {
      activeId: this.$route.query.id,//资讯id
      type:this.$route.query.type,//type类型判断1算法，2手动
      matchCompanyNum: 0,
      companyNum: 0,
      headTab: [
        {
          value: '自动匹配',
          id: 1,
          url: url1,
          url1: url2,
          Num: 0
        },
        {
          value: '确认已选',
          id: 2,
          url: url3,
          url1: url4,
          Num: 0
        }
      ],
      activeClass: 1,
      
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    clickTab(item) {
      this.activeClass = item.id
    },
    getHeaderNum() {
      if(this.type==1){
        
        artificialMatchingCompanyList({
        pageNum: 1,
        pageSize: 10,
        id: this.activeId,
        orders: '1',
        queryConditions: null
      }).then(({ data: res }) => {
        this.headTab.find((el) => el.id == 1).Num = res.data.total
       
      })
      }else{
        matchingCompanyList({
        pageNum: 1,
        pageSize: 10,
        id: this.activeId,
        orders: '1',
        queryConditions: null
      }).then(({ data: res }) => {
        this.headTab.find((el) => el.id == 1).Num = res.data.total
       
      })
      }
  
      
      matchingCompanyLists({
        pageNum: 1,
        pageSize: 10,
        id: this.activeId,
        orderByParam: null,
        queryConditions: null
      }).then(({ data: res }) => {
        this.headTab.find((el) => el.id == 2).Num = res.data.total

      })
    
    }
  
  },

  created() {
    this.getHeaderNum()
   
  },

  mounted() {}
}
</script>
<style scoped lang="less">
.head {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;

  .item {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    .text {
      display: flex;
      color: #bfbfbf;
      font-size: 14px;
      cursor: pointer;
      margin-bottom: 5px;

      .icon {
        width: 15px;
        height: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        margin: 0 5px;
      }
    }
    .bottom {
      display: flex;
      justify-content: center;

      .bottom_a {
        width: 88px;
        height: 6px;
        border-radius: 3px;
        background-color: #4e93fb;
      }
    }

    .active_class {
      color: #333;
      font-size: 14px;
    }
  }
}
</style>
