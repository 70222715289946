<template>
  <div>
    <div class="all_context">
      <div class="head">
        <div class="img">
          <video
            v-if="headData.videoList"
            :src="headData.videoList[0]"
            :controls="isControls"
          ></video>
          <div v-if="headData.videoList == null && headData.imageList">
            <img :src="headData.imageList[0]" alt="" />
          </div>

          <div v-if="headData.videoList">
            <div class="videoSuspend" v-if="isSuspend" @click="clickSuspend">
              <i class="el-icon-video-play"></i>
            </div>
          </div>
        </div>
        <div class="describe">
          <div class="title_label">
            <div class="left">
              <div class="title">{{ headData.informationName }}</div>
              <div class="type">
                资讯类型：
                <span v-if="headData.informationType == 1">应用场景</span>
                <span v-if="headData.informationType == 2">数智技术</span>
                <span v-if="headData.informationType == 3">竞情</span>
                <span v-if="headData.informationType == 4">案例</span>
                <span v-if="headData.informationType == 5">产品</span>
                <span v-if="headData.informationType == 6">服务商</span>
              </div>
              <div class="issueName">
                发布机构/人：<span>{{ headData.issueName }}</span>
              </div>
              <div class="label">
                <div class="item" v-for="(item, index) in headData.labelList" :key="index">
                  #{{ item }}
                </div>
              </div>
            </div>
            <div class="right">
              <div class="figure" @click="clickJoin">加入已选</div>
              <!-- <div class="person" @click="clickSet">设置关联度</div> -->
            </div>
          </div>
       
        </div>
      </div>
      <div class="context">
        <div class="state">
          <div class="left">
            <div class="label">关联度：</div>
            <el-radio-group v-model="radio" @change="clickRadio">
              <el-radio class="item" label="" >全部</el-radio>
             <el-radio class="item" :label="item.id" v-for="item in correlation" :key="item.id">{{
                item.value
              }}</el-radio>
            </el-radio-group>
          </div>

          <div class="right">
            <div class="icon">
              <img src="../../../../static/images/jr-icon-volume-copy.png" alt="" />
            </div>
            <div class="tex">最新刷新时间：</div>
            <div class="time">{{ headData.updateTime }}</div>
          </div>
        </div>
        <div class="seach_box">
        <el-input
          placeholder="请输入企业全称"
          v-model="query.queryConditions"
          class="input-with-select"
        >
          <el-button class="seach" slot="append" @click="search()">搜索</el-button>
        </el-input>
      </div>
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中..."
            @sort-change="changeSort"
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column type="selection" width="55" align="center"> </el-table-column>
            <el-table-column label="编号" align="center" width="140px" type="index">
            </el-table-column>

            <el-table-column label="logo" align="center">
              <template v-slot="row">
                <el-image
            v-if="row.companyLogo"
                slot="reference"
                :src="row.companyLogo"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>
              <el-image
                v-else
                slot="reference"
                src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/Group%203237.png"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>
              </template>
            </el-table-column>
            <el-table-column label="企业名称" align="center">
              <template slot-scope="scope">{{ scope.row.companyFullName }}</template>
            </el-table-column>
            <!-- <el-table-column label="所属行业" align="center">
              <template slot-scope="scope">{{ scope.row.industry }}</template>
            </el-table-column> -->
            <el-table-column label="所在城市" align="center">
              <template slot-scope="scope">{{ scope.row.province }}-{{ scope.row.city }}</template>
            </el-table-column>
            <el-table-column align="center" label="标签" width="200px">
              <template v-slot="{ row }">
                <span v-for="(o, index) in row.labelList" :key="index">
                  <span v-if="index < 4 && o != ''" class="span_tag">#{{ o }}</span>
                </span>
                <el-popover placement="bottom" width="230" trigger="hover">
                  <div
                    class="hidden_label_box"
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      align-content: center;
                      flex-wrap: wrap;
                    "
                  >
                    <span
                      class="span_tag"
                      v-for="(o, index) in row.labelList && row.labelList.length
                        ? row.labelList.filter((el, index) => index > 3)
                        : []"
                      :key="index"
                      style="
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        color: #4e93fb;
                        margin-right: 10px;
                      "
                      >#{{ o }}</span
                    >
                  </div>
                  <span
                    style="color: #4e93fb"
                    slot="reference"
                    v-if="row.labelList && row.labelList.length > 4"
                    >...{{ row.labelList.length - 4 }}</span
                  >
                </el-popover>
              </template>
            </el-table-column>  
            <el-table-column label="关联度" prop="correlation" align="center" sortable="custom">
              <template slot-scope="scope">
                <div class="correlationBox">
                  <div class="correlation">{{ scope.row.correlation }}%</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="企业简介" align="center" width="450">
              <template slot-scope="scope">
                <div
                  class="enterpriseIntro"
                  @mouseenter="enterpriseIntroShow = scope.row.id"
                  @mouseleave="enterpriseIntroShow = -1"
                >
                  {{ scope.row.enterpriseIntro }}
                </div>
                <div
                  class="enterpriseIntroShow"
                  v-show="enterpriseIntroShow == scope.row.id"
                  @mouseenter="enterpriseIntroShow = scope.row.id"
                  @mouseleave="enterpriseIntroShow = -1"
                >
                  {{ scope.row.enterpriseIntro }}
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="Pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="query.pageNum"
              :page-sizes="[10, 30, 50]"
              :page-size="query.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="add_select">
      <!-- 是否加入已选 -->
      <el-dialog :visible.sync="poolShow" width="347px">
        <div class="remove_pool" v-show="poolShow">
          <i class="el-icon-close" @click="clickClose"></i>
          <div class="text">确认是否加入已选</div>
          <div>
            <el-button type="primary" @click="clickPool">确认</el-button>
            <el-button @click="cancelPool">取消</el-button>
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- 设置关联度 -->
    <div class="correlation">
      <el-dialog :visible.sync="correlationShow" width="444px" @close="correlationClose">
        <div class="correlationBox">
          <div class="head">
            <div class="title">{{ correlationText }}</div>
          </div>
          <div class="context">
            <div class="input">
              <div class="label">关联度<span>*</span></div>
              <el-input v-model="correlationInput" placeholder="请输入关联度" clearable></el-input>
            </div>
            <div class="button">
              <div class="text" @click="clickSubmit">提交</div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { selectById, matchingCompanyList, insertCorrelation,artificialMatchingCompanyList } from '../../../api/content.js'
export default {
  components: {},
  props: {
    activeId: {
      type: String
    },
    type:{
      type: String
    }
  },
  data() {
    //这里存放数据
    return {
      headData: {},
      // 查看人数-->1   分享人数-->2  收藏人数-->3  想联系人数-->4
      activeName: '0',
      // 关联度
      correlation: [
        {
          value: '只选90%以上',
          id: 90
        },
        {
          value: '只选80%以上',
          id: 80
        },
        {
          value: '只选70%以上',
          id: 70
        }
      ],
      // 表格数据
      tableData: [],
      loading:false,
      // 分页总条数
      total: 0,
      // 视频按钮
      isControls: false,
      // 暂停按钮
      isSuspend: true,
      // 关联度
      radio: -1,
      // table表格按钮的选中数据存放处
      multipleSelection: [],
      // 表格发送数据
      query: {
        orders:'1',
        correlation: '',
        id: 1,
        pageNum: 1,
        pageSize: 10
      },
      // 表格企业简介
      enterpriseIntroShow: -1,
      // 加入已选
      poolShow: false,
      // 设置关联度
      correlationShow: false,
      // 设置关联度input
      correlationInput: '',
      // 设置关联度按钮的内容
      correlationText: '设置关联度'
    }
  },

  computed: {},
  filters: {
    timeFiltration(time) {
      if (time) {
        return time.split(' ')[0]
      }
    }
  },

  watch: {},
  //方法集合
  methods: {
  

    handleSizeChange(val) {
      this.query.pageSize = val
      this.search()
      
    },
    handleCurrentChange(val) {
      this.query.pageNum = val
      this.search()
    
    },
    //  加入已选的点击
    clickJoin() {
      this.poolShow = true
    },

    // 加入已选的弹出层 取消按钮
    clickClose() {
      this.poolShow = false
    },
    // 取消
    cancelPool() {
      this.poolShow = false
    },
    // 设置关联度的点击
    clickSet() {
      this.correlationShow = true
    },
    // 加入已选的确定按钮
    async clickPool() {
      if (this.multipleSelection.length == 0) {
        return this.$message('请勾选数据后重试')
      }
      const companyIds = []
      this.multipleSelection.forEach((item) => {
        companyIds.push(item.id)
      })
      
      const query = {
       
        companyIds,
        id: this.activeId
      }
    
      const { data: res } = await insertCorrelation(query)
    
      if (res.resultCode == 200) {
        this.poolShow = false
        this.search()
        this.$emit('getHeaderNum')
        return this.$message.success('加入成功')
      }

     
    },
    // 头部数据
    async getHeadData() {
      const { data: res } = await selectById({
        id: this.activeId
      })
      if (res.resultCode == 200) {
        this.headData = res.data
        sessionStorage.setItem('time', res.data.updateTime)
      
      }
    },
    // 点击暂停按钮
    clickSuspend() {
      this.isControls = true
      this.isSuspend = !this.isSuspend

    },
    // 关联度的按钮点击
    clickRadio() {
      this.query.correlation = this.radio
      this.search()
     
    },
    // table表格按钮的选中数据
    handleSelectionChange(val) {
      this.multipleSelection = val
    
    },
    async search() {
     
      this.query.id = this.activeId
      this.loading=true
      if(this.type==1){//算法配置
        const { data: res } = await artificialMatchingCompanyList(this.query)
        if (res.resultCode == 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading=false
        this.tableData.forEach((item) => {
          if (item.labelList != null) {
           
            item.labelList = item.labelList.slice(0, 5)
          }
        })
        if (this.tableData.length != 0) {
          this.$refs.multipleTable.toggleAllSelection()
        }
      }
      }else{
        const { data: res } = await matchingCompanyList(this.query)
        if (res.resultCode == 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading=false
        this.tableData.forEach((item) => {
          if (item.labelList != null) {
           
            item.labelList = item.labelList.slice(0, 5)
          }
        })
        if (this.tableData.length != 0) {
          this.$refs.multipleTable.toggleAllSelection()
        }
      }
      }
      
  
    },
   
    // 设置关联度的点击提交按钮
    clickSubmit() {
      this.query.correlation = this.correlationInput
      this.search()
      // console.log(this.correlationInput)
    },
    // 设置关联度点击关闭的回调
    correlationClose() {
      this.correlationInput = ''
    },
     //排序
     changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'correlation') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.query.orders = '2'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.query.orders = '1'
          this.search()
        } else {
          //updateAt列不排序时
          this.query.orders = '1'
          this.search()
        }
      }
    },
  },

  created() {
   
    this.getHeadData()
    this.search()
  },

  mounted() {}
}
</script>
<style scoped lang="less">
.all_context {
  padding: 16px 0px 0 0px;
  .seach_box {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
  .head {
    margin-bottom: 15px;
    height: 126px;
    display: flex;
    background-color: #ffffff;
    padding-right: 50px;
    border-radius: 8px;
    padding: 20px;
    .img {
      width: 209px;
        height: 126px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: 10px;
      img {
        width: 209px;
        height: 126px;
        border-radius: 4px;
      }
      video {
        width: 239px;
        height: 166px;
        border-radius: 4px;
      }
      .videoSuspend {
        width: 46px;
        height: 46px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        /deep/.el-icon-video-play {
          color: #fff;
          font-size: 46px;
        }
      }
    }
    .describe {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title_label {
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding-right: 50px;
        .left {
          flex: 1;
        
          .title {
            color: #333333;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
          }
          .type {
            margin: 12px 0px;
            font-size: 14px;
            color: #333;
          }
          .issueName {
            margin-bottom: 21px;
            font-size: 14px;
            color: #333;
          }
          .label {
            width: 450px;
            color: #4e93fb;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
            // 控制行数
            -webkit-line-clamp: 2; //超出两行隐藏
            -webkit-box-orient: vertical; // 从上到下垂直排列子元素

            .item {
              display: inline-block;
              margin-right: 11px;
              margin-bottom: 10px;
            }
          }
        }
        .right {
          display: flex;
          margin-top: 15px;
          // flex-direction: column;
          // justify-content: space-evenly;
          // align-items: center;
          .figure {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #4e93fb;
            width: 85px;
            height: 40px;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            border-radius: 4px;
            cursor: pointer;
            margin-right: 20px;
          }
          .person {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f5f5f5;
            width: 85px;
            height: 40px;
            color: #4e93fb;
            font-size: 14px;
            font-weight: 400;
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
    
    }
  }
  .context {
    background-color: #ffffff;
    padding: 0 21px 0 30px;
    border-radius: 8px;
    .span_tag {
  font-size: 14px;
  line-height: 20px;
  color: #4e93fb;
  margin-right: 10px;
  cursor: pointer;
}
    .state {
      height: 72px;
      border-bottom: 1px dashed #f3f3f3;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        .item {
          color: #333333;
          font-size: 14px;
          cursor: pointer;
          padding: 15px 20px;
        }
      }
      .right {
        display: flex;
        padding-right: 25px;
        .icon {
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .tex {
          margin-left: 7px;
          color: #595959;
        }
        .time {
          color: #333333;
        }
      }
      /deep/.el-radio {
        margin-right: 0;
      }
    }
    .time_search {
      height: 80px;
      display: flex;
      align-items: center;
      padding-right: 10px;
      justify-content: space-between;
      position: relative;
      .time {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        position: absolute;
        left: 0;

        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        // .item {
        //   color: #333333;
        //   font-size: 14px;
        //   cursor: pointer;
        //   padding: 15px 20px;
        // }
      }
      .search {
        display: flex;
        position: absolute;
        right: 0;
        .search_input {
          width: 430px;
        }
        .search_div {
          width: 74px;
          height: 40px;
          background-color: #448aff;
          color: #fff;
          border-radius: 2px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          transform: translateX(-2px);
        }
      }
    }

    .table {
      .imgbox {
        display: flex;
        justify-content: center;

        .img {
          width: 34px;
          height: 34px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .labelBox {
        .labelItem {
        }
      }
      .correlationBox {
        display: flex;
        align-items: center;
        justify-content: center;
        .correlation {
          background-color: #fd6161;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 55px;
          height: 24px;
          color: #fff;
          font-size: 12px;
        }
      }

      .enterpriseIntro {
        // width: 100%;
        // height: 100%;
        width: 376px;
        overflow: hidden; //超出隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
        // 控制行数
        -webkit-line-clamp: 2; //超出两行隐藏
        -webkit-box-orient: vertical; // 从上到下垂直排列子元素
        color: #333333;
        position: relative;
      }
      .enterpriseIntroShow {
        position: absolute;
        // right: 0%;
        // bottom: -23%;
        border-radius: 8px;
        // transform: translateX(50%);
        width: 376px;
        padding: 10px 7px 8px 13px;
        background-color: #fff;
        z-index: 999;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }

      /deep/.el-table__body-wrapper {
        overflow: visible;
      }
      /deep/.el-table {
        overflow: visible;
      }
    }
    .Pagination {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 20px 0px ;
    }
  }
}

.add_select {
  .remove_pool {
    box-sizing: border-box;
    width: 348px;
    height: 150px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0px 0px 2px #d4d4d4;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 42px 0 20px 0;
    align-items: center;
    .el-icon-close {
      position: absolute;
      right: 11px;
      top: 11px;
      color: #b6b6b4;
      font-size: 18px;
      cursor: pointer;
    }
    .text {
      margin-bottom: 23px;
    }
    /deep/.el-button {
      width: 112px;
      height: 40px;
    }
  }
  /deep/.el-dialog {
    margin-top: 40vh !important;
  }
}

.correlation {
  /deep/.el-dialog {
    border-radius: 4px;
    position: relative;
  }
  /deep/.el-dialog__body {
    padding: 28px 20px 16px 20px;
  }
  .correlationBox {
    .head {
      position: absolute;
      left: 0;
      top: 12px;
      // transform: translate(-20px, -50px);
      .title {
        border-left: 4px solid #448aff;
        padding-left: 15px;
        font-size: 16px;
        color: #333333;
        font-weight: 500;
      }
    }
    .context {
      .input {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .label {
          position: relative;
          font-size: 14px;
          color: #333333;
          span {
            position: absolute;
            right: -9px;
            top: -2px;
            color: #f05252;
          }
        }
        /deep/.el-input {
          width: 322px;
        }
      }
      .button {
        width: 100%;
        display: flex;
        justify-content: center;
        .text {
          width: 64px;
          height: 36px;
          cursor: pointer;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 14px;
          background-color: #448aff;
          margin-top: 31px;
        }
      }
    }
  }
}
</style>
